$blue: #121212;//#22332f;
$black: #000000;
$gray-200: #6F6F6F;
$black-700: #000000B2;
$green-700: #007D88B2;
$green: #27AE60;
$red: #FF0000;
$yellow: #f1b44c;
$cyan: #FFFFFFB2;
$white: #fff;
$gray-100 :#EFF2F5;
$gray-700 :#D9D9D9;
$low: #E1E7FD;
$high: #F9E3E2;
$medium: #FCEED8;
$inner-info : #11543e;
$green-400: #07271e;
$inner-screen-primary-clr: #141414;
$primary: $blue;
$secondary: $black-700;
$success: $green-700;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $black;
$white: $white;
$gray-light: $gray-100;
$bg-tag : rgba(14, 55, 75, 0.1);
$gray-dark : $gray-700;
$inner-info: $inner-info;
$primary-400: $green-400;




$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
);

// 
// Color.scss
//
.inner-screen-primary-clr{
    color: $inner-screen-primary-clr;
}
.text-primary {
    color: $primary !important;
}
.text-primary-400 {
    color: $primary-400;
}

.text-secondary {
    color: $secondary !important;
}

.text-success {
    color: $success !important;
}

.text-info {
    color: $info !important;
}

.text-warning {
    color: $warning !important;
}

.text-danger {
    color: $red !important;
}

.text-light {
    color: $light !important;
}

.text-dark {
    color: $dark !important;
}

.text-green {
    color: $green !important;
}

.text-inner-info {
    color: $inner-info ;
    font-weight: 600;
}


// 
// background.scss
//

.bg-primary {
    background-color: $primary !important;
}

.bg-secondary {
    background-color: $secondary !important;
}

.bg-success {
    background-color: $success !important;
}

.bg-info {
    background-color: $info !important;
}

.bg-warning {
    background-color: $warning !important;
}

.bg-danger {
    background-color: $red !important;
}

.bg-light {
    background-color: $light !important;
}

.bg-select {
    background-color: #1212120D !important;
}

.bg-dark {
    background-color: $dark !important;
}

.bg-gray-light {
    background-color: $gray-light !important;
}

.bg-tag {
    background: $bg-tag !important;
}
.bg-gray-dark{
    background-color: $gray-dark;
}
.bg-high{
    background-color: $high !important;
    color: #752522 !important;
}
.bg-low{
    background-color: $low !important;
    color: #302E7C !important;
}
.bg-medium{
    background-color: $medium !important;
    color: #73321B !important;
}
.bg-green{
    background: #D5FAF1 !important;
    color: #254D4A !important;
}
.bg-dot-medium{
    background-color: #73321B;
}
.bg-dot-low {
    background-color: #302E7C;
}
.bg-dot-high {
    background-color: #752522;
}
.bg-refunded {
    background-color: $gray-light;
    color: #575755b3;
}
.bg-white{
    background-color: var($white);
}

// fontweight 
$fw-medium: 500;
$font-weight-semibold: 600;

//Box-shadow
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, .03);
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);
$box-shadow-inset: inset 0 1px 2px rgba($black, .075);

