.width-fit-content{
    width: fit-content !important;
}

.w-1 {
    width: 1% !important;
}

.w-2 {
    width: 2% !important;
}

.w-3 {
    width: 3% !important;
}

.w-4 {
    width: 4% !important;
}

.w-5 {
    width: 5% !important;
}

.w-6 {
    width: 6% !important;
}

.w-7 {
    width: 7% !important;
}

.w-8 {
    width: 8% !important;
}

.w-9 {
    width: 9% !important;
}

.w-10 {
    width: 10% !important;
}

.w-11 {
    width: 11% !important;
}

.w-12 {
    width: 12% !important;
}

.w-13 {
    width: 13% !important;
}

.w-14 {
    width: 14% !important;
}

.w-15 {
    width: 15% !important;
}

.w-16 {
    width: 16% !important;
}

.w-17 {
    width: 17% !important;
}

.w-18 {
    width: 18% !important;
}

.w-19 {
    width: 19% !important;
}

.w-20 {
    width: 20% !important;
}

.w-21 {
    width: 21% !important;
}

.w-22 {
    width: 22% !important;
}

.w-23 {
    width: 23% !important;
}

.w-24 {
    width: 24% !important;
}

.w-25 {
    width: 25% !important;
}

.w-26 {
    width: 26% !important;
}

.w-27 {
    width: 27% !important;
}

.w-28 {
    width: 28% !important;
}

.w-29 {
    width: 29% !important;
}

.w-30 {
    width: 30% !important;
}

.w-31 {
    width: 31% !important;
}

.w-32 {
    width: 32% !important;
}

.w-33 {
    width: 33% !important;
}

.w-34 {
    width: 34% !important;
}

.w-35 {
    width: 35% !important;
}

.w-36 {
    width: 36% !important;
}

.w-37 {
    width: 37% !important;
}

.w-38 {
    width: 38% !important;
}

.w-39 {
    width: 39% !important;
}

.w-40 {
    width: 40% !important;
}

.w-41 {
    width: 41% !important;
}

.w-42 {
    width: 42% !important;
}

.w-43 {
    width: 43% !important;
}

.w-44 {
    width: 44% !important;
}

.w-45 {
    width: 45% !important;
}

.w-46 {
    width: 46% !important;
}

.w-47 {
    width: 47% !important;
}

.w-48 {
    width: 48% !important;
}

.w-49 {
    width: 49% !important;
}

.w-50 {
    width: 50% !important;
}

.w-51 {
    width: 51% !important;
}

.w-52 {
    width: 52% !important;
}

.w-53 {
    width: 53% !important;
}

.w-54 {
    width: 54% !important;
}

.w-55 {
    width: 55% !important;
}

.w-56 {
    width: 56% !important;
}

.w-57 {
    width: 57% !important;
}

.w-58 {
    width: 58% !important;
}

.w-59 {
    width: 59% !important;
}

.w-60 {
    width: 60% !important;
}

.w-61 {
    width: 61% !important;
}

.w-62 {
    width: 62% !important;
}

.w-63 {
    width: 63% !important;
}

.w-64 {
    width: 64% !important;
}

.w-65 {
    width: 65% !important;
}

.w-66 {
    width: 66% !important;
}

.w-67 {
    width: 67% !important;
}

.w-68 {
    width: 68% !important;
}

.w-69 {
    width: 69% !important;
}

.w-70 {
    width: 70% !important;
}

.w-71 {
    width: 71% !important;
}

.w-72 {
    width: 72% !important;
}

.w-73 {
    width: 73% !important;
}

.w-74 {
    width: 74% !important;
}

.w-75 {
    width: 75% !important;
}

.w-76 {
    width: 76% !important;
}

.w-77 {
    width: 77% !important;
}

.w-78 {
    width: 78% !important;
}

.w-79 {
    width: 79% !important;
}

.w-80 {
    width: 80% !important;
}

.w-81 {
    width: 81% !important;
}

.w-82 {
    width: 82% !important;
}

.w-83 {
    width: 83% !important;
}

.w-84 {
    width: 84% !important;
}

.w-85 {
    width: 85% !important;
}

.w-86 {
    width: 86% !important;
}

.w-87 {
    width: 87% !important;
}

.w-88 {
    width: 88% !important;
}

.w-89 {
    width: 89% !important;
}

.w-90 {
    width: 90% !important;
}

.w-91 {
    width: 91% !important;
}

.w-92 {
    width: 92% !important;
}

.w-93 {
    width: 93% !important;
}

.w-94 {
    width: 94% !important;
}

.w-95 {
    width: 95% !important;
}

.w-96 {
    width: 96% !important;
}

.w-97 {
    width: 97% !important;
}

.w-98 {
    width: 98% !important;
}

.w-99 {
    width: 99% !important;
}

.w-100 {
    width: 100% !important;
}