.border-radius-0 {
    border-radius: 0 !important;
}

.least-border-radius {
    border-radius: 0.5 !important;
}

.border-radius-1 {
    border-radius: 1px !important;
}

.border-radius-2 {
    border-radius: 2px !important;
}

.border-radius-3 {
    border-radius: 3px !important;
}

.border-radius-4 {
    border-radius: 4px !important;
}

.border-radius-5 {
    border-radius: 5px !important;
}

.border-radius-6 {
    border-radius: 6px !important;
}

.border-radius-7 {
    border-radius: 7px !important;
}

.border-radius-8 {
    border-radius: 8px !important;
}

.border-radius-9 {
    border-radius: 9px !important;
}

.border-radius-10 {
    border-radius: 10px !important;
}

.border-radius-11 {
    border-radius: 11px !important;
}

.border-radius-12 {
    border-radius: 12px !important;
}

.border-radius-13 {
    border-radius: 13px !important;
}

.border-radius-14 {
    border-radius: 14px !important;
}

.border-radius-15 {
    border-radius: 15px !important;
}

.border-radius-16 {
    border-radius: 16px !important;
}

.border-radius-17 {
    border-radius: 17px !important;
}

.border-radius-18 {
    border-radius: 18px !important;
}

.border-radius-19 {
    border-radius: 19px !important;
}

.border-radius-20 {
    border-radius: 20px !important;
}

.border-radius-21 {
    border-radius: 21px !important;
}

.border-radius-22 {
    border-radius: 22px !important;
}

.border-radius-50 {
    border-radius: 50px !important;
}

.border-1 {
    border: 1px solid #22332f !important;
}

.border-light-1 {
    border: 1px solid #d3dbe3 !important;
}

.dashed-border-1 {
    border: 1px dashed #22332f !important;
}

.dotted-border-1 {
    border: 2px dotted #22332f !important;
}

.border-bottom-1 {
    border: 0 0 1px 0 solid #22332f !important;
}

//********************toggle CSS*************************//

.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 16px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 1px;
    top: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #22332f;
}

input:focus+.slider {
    box-shadow: none;
}

input:checked+.slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
}

.slider.round {
    border-radius: 34px;

    &:before {
        border-radius: 50%;
    }
}

