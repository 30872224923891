body {
  overflow-x: hidden !important;
}

.pointer {
  cursor: pointer;
}

.limit-line-length-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.limit-line-height-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.small-margin {
  margin-top: 2px;
}

//modal animations

.from-bottom-right {
  .modal-dialog {
    position: absolute !important;
    right: 10px !important;
    width: 340px !important;
    bottom: -10px !important;
    max-height: calc(100vh - 78px) !important;
    border-radius: 16px;

    .modal-content {
      border: none;
      height: 100%;
      padding-bottom: 8px;
      background: rgb(203 247 252 / 94%);
    }
  }
}

.from-bottom-right.modal.fade .modal-dialog {
  transition: transform 0.5s ease-out;
  transform: translateX(100%);
}

.from-bottom-right.modal.show .modal-dialog {
  transform: none;
  animation: rightAnimation 0.5s ease !important;
}

@keyframes rightAnimation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-3px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(3px);
  }
}

.table-responsive::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.table-responsive:hover {
  overflow: auto !important;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: white;
}

.table-responsive:hover::-webkit-scrollbar-track {
  background-color: #f3f1f1;
}

.table-responsive:hover::-webkit-scrollbar-thumb {
  visibility: visible !important
}

.auto-hide-scroll-bar::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.auto-hide-scroll-bar:hover {
  overflow: auto !important;
}

.auto-hide-scroll-bar::-webkit-scrollbar-track {
  background-color: white;
}

.auto-hide-scroll-bar:hover::-webkit-scrollbar-track {
  background-color: #f3f1f1;
}

.auto-hide-scroll-bar:hover::-webkit-scrollbar-thumb {
  visibility: visible !important
}

.custom-table-filter-div-height {
  height: calc(100vh - 200px);
  transition: all 0.3s;

  tr {
    position: relative;
  }
}

// @media screen and (min-width: 1000px) {
.custom-height {
  height: calc(100vh - 101px);
  background: white;

  .tab-pane {
    height: 100%;
  }
}

.custom-table-div-more-height {
  height: calc(100vh - 148px);

  .border-top-table {
    position: sticky;
    top: 0px;
  }

  .table-responsive {
    flex-grow: 1;
    height: 100%;


    table {
      max-height: 100%
    }
  }
}

.custom-table-div-height-home {
  @extend .custom-table-div-more-height;
  height: calc(100vh - 127px);
  transition: all 0.3s;
}

.custom-details-div-height {
  height: calc(100vh - 172px);
}

.custom-table-div-less-height {
  @extend .custom-table-div-more-height;
  height: calc(100vh - 168px);
}

.custom-table-div-crm-height {
  @extend .custom-table-div-more-height;
  height: calc(100vh - 152px);

  .fixed-table {
    max-height: 100% !important;
  }
}

.table-head-sticky {
  position: sticky;
  top: 0px;
  z-index: 2;
}

.user-settings-div-height {
  height: calc(100vh - 258px);
  overflow-y: auto !important;
  @extend .table-head-sticky
}

.height-max-content {
  height: max-content;
}

.mat-mdc-option {
  background: white !important;
}

.mat-mdc-paginator-container {
  min-height: 42px;
}

.ck-error {
  .ck.ck-editor {
    border: 1px solid red;
  }
}
.tableColumnLists{
  mat-form-field{
    box-shadow: 0px 16px 20px -24px;
  }
}

.cdk-overlay-pane {
  min-width: fit-content !important;
  margin-top: 2px;
}

.page_customised-sec {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 4px !important;
    padding-bottom: 0px !important;
  }
}

.customised-sec {
  span{
    text-align: left;
  }
  button {
    height: 32px;
  }

  .mat-mdc-form-field-infix {
    width: fit-content !important;
    min-width: 60px !important;
    min-height: auto !important;
    // height: 34px !important;
    padding-top: 0px !important;
  }

  .cust-date-picker {
    .mat-mdc-form-field-infix {
      width: 180px !important;
      min-width: 60px !important;
      min-height: auto !important;
      height: 34px !important;
      padding-top: 4px !important;
    }
  }

  .mat-mdc-form-field-flex {
    height: auto !important;
  }

  .searching-box .mat-mdc-form-field-infix {
    padding: 1px !important;
  }

  .mat-mdc-select-value {
    padding-right: 8px !important;
  }

  .mat-mdc-form-field-icon-prefix>.mat-icon {
    padding: 1px 0px 0px 8px !important;
    scale: 0.8;
  }

  .searching-box .mdc-notched-outline {
    height: 100% !important;
  }
}

.search-customised-sec {
  @extend .customised-sec;

  .mat-mdc-form-field-infix {
    padding-top: 0px !important;
  }
}

.mat-customised-sec {
  .mat-mdc-form-field-flex {
    height: 34px !important;
    min-height: auto !important;
  }
}

.mat-date-range-input-container {
  height: 100%;
}

.container-width-height {
  max-width: 100%;
  height: calc(100vh - 130px);
}

::-webkit-scrollbar {
  height: 2px !important;
}

.ai-modal .modal-content {
  border-radius: 10px;
}

.ck.ck-balloon-panel {
  z-index: 100000 !important;
}

.ai-modal .modal-content {
  border-radius: 24px;
}

.line-height-1 {
  line-height: 1;
}

//NEW UI (May-2024)

.margin-right-14 {
  margin-right: 14px;
}

tr:hover{
  background: #F8F8F8 !important;
}

.newUI {
  .bottom-border {
    border-bottom: 1px solid #F4F4F4 !important;
  }
  .no-gutters{
    align-items: center;
  }

  .info-accord {
    .text-inner-info {
      color: #09090B;
      font-weight: 400;
      font-size: 14px;
    }

    .text-name {
      color: #09090B;
      letter-spacing: 0px;
      font-weight: 500;
      font-size: 14px;
      align-items: center;
    }
  }
}
.word-break{
  word-break: break-all;
}

.action-btn {
  box-shadow: 0px 1px 1px 0px rgba(18, 18, 18, 0.10), 0px 0px 0px 1px rgba(18, 18, 18, 0.07), 0px 1px 3px 0px rgba(18, 18, 18, 0.10);
}

.table-header-color {
  color: #09090B !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}

.title-text-clr {
  color: #151515;
}

.label-text-clr {
  color: #09090B;
}

.overflow-hidden {
  overflow: hidden;
}
.flex-shrink{
  flex: 1 1 auto;
}

//SLIDER new Design

.switch {
  width: 40px;
  height: 24px;
}

input:checked+.slider:before {
  transform: translateX(16px);
}

.slider:before {
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 1px;
  top: 2px;
}

.ck-toolbar.ck-toolbar_grouping {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 8px 8px 0px 0px !important;
}

.ck.ck-content.ck-editor__editable {
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px 0px 8px 8px !important;
  border-top: 1px solid rgb(209, 205, 205);
}

.ck-editor {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 1px 16px 2px rgba(18, 18, 18, 0.06), 0px 0px 0px 1px rgba(18, 18, 18, 0.1);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-style: solid !important;
  border-color: #f44336 !important;
  border-width: 1px 1px 1px 0px !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading {
  border-style: solid !important;
  border-color: #f44336 !important;
  border-width: 1px 0px 1px 1px !important;
}
.border-none{
  border: none !important;
}
.new-design-padding{
  padding: 0px 11px 0px 11px;
}

.home-page-table-view {
  .table-responsive {
    table {
      thead {
        z-index: 2;

        th {
          font-size: 14px;
        }
      }

      tbody {
        tr td {
          font-weight: 500;
          font-size: 12px;
          min-width: 100px;
          max-width: 150px;
          word-break: break-all;
        }
      }
    }
  }
}



//      END      //