.card {
    margin-bottom: 24px;
}

.card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: $font-weight-semibold;
}

.card-title-desc {
    color: $secondary;
    margin-bottom: 24px;
}