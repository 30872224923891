//
// _nav.scss
//

.nav-tabs,
.nav-pills {
    >li {
        >a {
            color: $black-700;
            font-weight: $fw-medium;
        }
    }
}

.nav-pills {
    >a {
        color: $black-700;
        font-weight: $fw-medium;
    }
}

.nav-pills .nav-link {
    &.active {
        background-color: $primary !important;
        color: $white !important;
        font-weight: 400 !important;
    }

    &:hover {
        color: $primary;
        font-weight: 600;
    }
}

.nav-tabs-custom {
    border-bottom: 1px solid #c3c3c380;

    .nav-item {
        position: relative;
        color: $dark;

        .nav-link {
            border: none;
            padding: 10px 16px;
            &::after {
                content: "";
                background: $primary;
                height: 3px;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: -1px;
                transition: all 250ms ease 0s;
                transform: scale(0);
            }

            &.active {
                background-color: transparent;
                color: black;
                font-weight: 600;
                // background-color: #f1fffd;
                // color: $primary;
                // font-weight: 700;

                &:after {
                    transform: scale(1);
                }
            }
        }

        .nav-link {

            &:hover,
            &:focus {
                color: black;
            }
        }
    }
}

// vertical nav

.vertical-nav {
    .nav {
        .nav-link {
            padding: 24px 16px;
            text-align: center;
            margin-bottom: 8px;

            .nav-icon {
                font-size: 24px;
            }
        }
    }
}