h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: -0.1px !important;
}

.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

.font-size-1 {
    font-size: 1px !important;
    letter-spacing: -0.1px !important;
}

.font-size-2 {
    font-size: 2px !important;
    letter-spacing: -0.1px !important;
}

.font-size-3 {
    font-size: 3px !important;
    letter-spacing: -0.1px !important;
}

.font-size-4 {
    font-size: 4px !important;
    letter-spacing: -0.1px !important;
}

.font-size-5 {
    font-size: 5px !important;
    letter-spacing: -0.1px !important;
}

.font-size-6 {
    font-size: 6px !important;
    letter-spacing: -0.1px !important;
}

.font-size-7 {
    font-size: 7px !important;
    letter-spacing: -0.1px !important;
}

.font-size-8 {
    font-size: 8px !important;
    letter-spacing: -0.1px !important;
}

.font-size-9 {
    font-size: 9px !important;
    letter-spacing: -0.1px !important;
}

.font-size-10 {
    font-size: 10px !important;
    letter-spacing: -0.1px !important;
}

.font-size-11 {
    font-size: 11px !important;
    letter-spacing: -0.1px !important;
}

.font-size-12 {
    font-size: 12px !important;
    letter-spacing: -0.1px !important;
}

.font-size-13 {
    font-size: 13px !important;
    letter-spacing: -0.1px !important;
}

.font-size-14 {
    font-size: 14px !important;
    letter-spacing: -0.1px !important;
}

.font-size-15 {
    font-size: 15px !important;
    letter-spacing: -0.1px !important;
}

.font-size-16 {
    font-size: 16px !important;
    letter-spacing: -0.1px !important;
}

.font-size-17 {
    font-size: 17px !important;
    letter-spacing: -0.1px !important;
}

.font-size-18 {
    font-size: 18px !important;
    letter-spacing: -0.1px !important;
}

.font-size-19 {
    font-size: 19px !important;
    letter-spacing: -0.1px !important;
}

.font-size-20 {
    font-size: 20px !important;
    letter-spacing: -0.1px !important;
}

.font-size-21 {
    font-size: 21px !important;
    letter-spacing: -0.1px !important;
}

.font-size-22 {
    font-size: 22px !important;
    letter-spacing: -0.1px !important;
}

.font-size-23 {
    font-size: 23px !important;
    letter-spacing: -0.1px !important;
}

.font-size-24 {
    font-size: 24px !important;
    letter-spacing: -0.1px !important;
}

.font-size-25 {
    font-size: 25px !important;
    letter-spacing: -0.1px !important;
}

.font-size-26 {
    font-size: 26px !important;
    letter-spacing: -0.1px !important;
}

.font-size-27 {
    font-size: 27px !important;
    letter-spacing: -0.1px !important;
}

.font-size-28 {
    font-size: 28px !important;
    letter-spacing: -0.1px !important;
}

.font-size-29 {
    font-size: 29px !important;
    letter-spacing: -0.1px !important;
}

.font-size-30 {
    font-size: 30px !important;
    letter-spacing: -0.1px !important;
}

.font-size-31 {
    font-size: 31px !important;
    letter-spacing: -0.1px !important;
}

.font-size-32 {
    font-size: 32px !important;
    letter-spacing: -0.1px !important;
}

.font-size-33 {
    font-size: 33px !important;
    letter-spacing: -0.1px !important;
}

.font-size-34 {
    font-size: 34px !important;
    letter-spacing: -0.1px !important;
}

.font-size-35 {
    font-size: 35px !important;
    letter-spacing: -0.1px !important;
}

.font-size-36 {
    font-size: 36px !important;
    letter-spacing: -0.1px !important;
}

.font-size-37 {
    font-size: 37px !important;
    letter-spacing: -0.1px !important;
}

.font-size-38 {
    font-size: 38px !important;
    letter-spacing: -0.1px !important;
}

.font-size-39 {
    font-size: 39px !important;
    letter-spacing: -0.1px !important;
}

.font-size-40 {
    font-size: 40px !important;
    letter-spacing: -0.1px !important;
}

.font-size-41 {
    font-size: 41px !important;
    letter-spacing: -0.1px !important;
}

.font-size-42 {
    font-size: 42px !important;
    letter-spacing: -0.1px !important;
}

.font-size-43 {
    font-size: 43px !important;
    letter-spacing: -0.1px !important;
}

.font-size-44 {
    font-size: 44px !important;
    letter-spacing: -0.1px !important;
}

.font-size-45 {
    font-size: 45px !important;
    letter-spacing: -0.1px !important;
}

.font-size-46 {
    font-size: 46px !important;
    letter-spacing: -0.1px !important;
}

.font-size-47 {
    font-size: 47px !important;
    letter-spacing: -0.1px !important;
}

.font-size-48 {
    font-size: 48px !important;
    letter-spacing: -0.1px !important;
}

.font-size-49 {
    font-size: 49px !important;
    letter-spacing: -0.1px !important;
}

.font-size-50 {
    font-size: 50px !important;
    letter-spacing: -0.1px !important;
}

.font-size-51 {
    font-size: 51px !important;
    letter-spacing: -0.1px !important;
}

.font-size-52 {
    font-size: 52px !important;
    letter-spacing: -0.1px !important;
}

.font-size-53 {
    font-size: 53px !important;
    letter-spacing: -0.1px !important;
}

.font-size-54 {
    font-size: 54px !important;
    letter-spacing: -0.1px !important;
}

.font-size-55 {
    font-size: 55px !important;
    letter-spacing: -0.1px !important;
}

.font-size-56 {
    font-size: 56px !important;
    letter-spacing: -0.1px !important;
}

.font-size-57 {
    font-size: 57px !important;
    letter-spacing: -0.1px !important;
}

.font-size-58 {
    font-size: 58px !important;
    letter-spacing: -0.1px !important;
}

.font-size-59 {
    font-size: 59px !important;
    letter-spacing: -0.1px !important;
}

.font-size-60 {
    font-size: 60px !important;
    letter-spacing: -0.1px !important;
}

.font-size-61 {
    font-size: 61px !important;
    letter-spacing: -0.1px !important;
}

.font-size-62 {
    font-size: 62px !important;
    letter-spacing: -0.1px !important;
}

.font-size-63 {
    font-size: 63px !important;
    letter-spacing: -0.1px !important;
}

.font-size-64 {
    font-size: 64px !important;
    letter-spacing: -0.1px !important;
}

.font-size-65 {
    font-size: 65px !important;
    letter-spacing: -0.1px !important;
}

.font-size-66 {
    font-size: 66px !important;
    letter-spacing: -0.1px !important;
}

.font-size-67 {
    font-size: 67px !important;
    letter-spacing: -0.1px !important;
}

.font-size-68 {
    font-size: 68px !important;
    letter-spacing: -0.1px !important;
}

.font-size-69 {
    font-size: 69px !important;
    letter-spacing: -0.1px !important;
}

.font-size-70 {
    font-size: 70px !important;
    letter-spacing: -0.1px !important;
}

.font-size-71 {
    font-size: 71px !important;
    letter-spacing: -0.1px !important;
}

.font-size-72 {
    font-size: 72px !important;
    letter-spacing: -0.1px !important;
}

.font-size-73 {
    font-size: 73px !important;
    letter-spacing: -0.1px !important;
}

.font-size-74 {
    font-size: 74px !important;
    letter-spacing: -0.1px !important;
}

.font-size-75 {
    font-size: 75px !important;
    letter-spacing: -0.1px !important;
}

.font-size-76 {
    font-size: 76px !important;
    letter-spacing: -0.1px !important;
}

.font-size-77 {
    font-size: 77px !important;
    letter-spacing: -0.1px !important;
}

.font-size-78 {
    font-size: 78px !important;
    letter-spacing: -0.1px !important;
}

.font-size-79 {
    font-size: 79px !important;
    letter-spacing: -0.1px !important;
}

.font-size-80 {
    font-size: 80px !important;
    letter-spacing: -0.1px !important;
}

.font-size-81 {
    font-size: 81px !important;
    letter-spacing: -0.1px !important;
}

.font-size-82 {
    font-size: 82px !important;
    letter-spacing: -0.1px !important;
}

.font-size-83 {
    font-size: 83px !important;
    letter-spacing: -0.1px !important;
}

.font-size-84 {
    font-size: 84px !important;
    letter-spacing: -0.1px !important;
}

.font-size-85 {
    font-size: 85px !important;
    letter-spacing: -0.1px !important;
}

.font-size-86 {
    font-size: 86px !important;
    letter-spacing: -0.1px !important;
}

.font-size-87 {
    font-size: 87px !important;
    letter-spacing: -0.1px !important;
}

.font-size-88 {
    font-size: 88px !important;
    letter-spacing: -0.1px !important;
}

.font-size-89 {
    font-size: 89px !important;
    letter-spacing: -0.1px !important;
}

.font-size-90 {
    font-size: 90px !important;
    letter-spacing: -0.1px !important;
}

.font-size-91 {
    font-size: 91px !important;
    letter-spacing: -0.1px !important;
}

.font-size-92 {
    font-size: 92px !important;
    letter-spacing: -0.1px !important;
}

.font-size-93 {
    font-size: 93px !important;
    letter-spacing: -0.1px !important;
}

.font-size-94 {
    font-size: 94px !important;
    letter-spacing: -0.1px !important;
}

.font-size-95 {
    font-size: 95px !important;
    letter-spacing: -0.1px !important;
}

.font-size-96 {
    font-size: 96px !important;
    letter-spacing: -0.1px !important;
}

.font-size-97 {
    font-size: 97px !important;
    letter-spacing: -0.1px !important;
}

.font-size-98 {
    font-size: 98px !important;
    letter-spacing: -0.1px !important;
}

.font-size-99 {
    font-size: 99px !important;
    letter-spacing: -0.1px !important;
}

.font-size-100 {
    font-size: 100px !important;
    letter-spacing: -0.1px !important;
}