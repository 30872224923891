//
// _buttons.scss
//

.btn-group {
  .btn-default {
    background-color: $secondary;
    color: $light;
    border: 1px solid $secondary;

    &.btn-primary {
      background-color: $primary;
      border-color: $primary;
      color: $white;
    }
  }
}

.btn-primary {
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.30) !important;
  background: #121212 !important;
  box-shadow: 0px 0px 0px 1px #121212;
}
.btn-light {
  padding: 6px 12px !important;
  background: #FAFAFA !important;
  box-shadow: 0px 1px 1px 0px rgba(18, 18, 18, 0.20), 0px 0px 0px 1px rgba(18, 18, 18, 0.10), 0px 1px 3px 0px rgba(18, 18, 18, 0.10) !important;
}

// .btn-primary:hover {
//   background-color: $primary !important;
// }

.btn button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark,
.btn-secondary {
  color: $secondary !important;
}

.btn-outline-light {
  color: $light;
}

.btn-outline-dark,
.btn-dark {

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show>&.dropdown-toggle,
  &:hover {
    color: $light;
  }
}

.custom-filter-btn {
  border-radius: 8px;
  border: 1px solid #DEDEDE !important;
  background: #ffffff !important;
  height: 36px !important;
  box-shadow: none !important;
  color: #596066;
  padding: 0px 10px;
}

// example 1

.btn-group-example {
  .btn {
    position: relative;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:before {
      content: "OR";
      position: absolute;
      font-size: 10px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      background-color: $secondary;
      color: $light;
      border: 1px solid $white;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

// Example 2

.btn-label {
  position: relative;
  padding-left: 44px;

  .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($white, 0.1);
    border-right: 1px solid rgba($white, 0.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-light {
    padding: 6px 12px !important;
    background: #f10202 !important;
    box-shadow: 0px 1px 1px 0px rgba(18, 18, 18, 0.20), 0px 0px 0px 1px rgba(18, 18, 18, 0.10), 0px 1px 3px 0px rgba(18, 18, 18, 0.10) !important;

    .label-icon {
      background-color: rgba($dark, 0.1);
      border-right: 1px solid rgba($dark, 0.2);
    }
  }
}

.btn-outline-primary {
  border-color: $primary !important;
  color: $primary;

  &:hover {
    background-color: $primary;
  }

  &:active {
    background-color: $primary !important;
  }
}

.dropDownfilter {
  .mat-mdc-text-field-wrapper {
    border: 1px solid #DEDEDE !important;
    background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0.00) 100%), #fff !important;
    box-shadow: 0px 0px 0px 1px #12121212 !important;
  }

  .mat-mdc-select-arrow,
  .mat-mdc-select-value {
    color: black !important;
  }
}