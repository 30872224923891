/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "../src/assets/scss/custom.scss";
@import "../src/assets/scss/fonts.scss";
@import "../src/assets/scss/borders.scss";
@import "../src/assets/scss/height.scss";
@import "../src/assets/scss/width.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: Inter !important;
  src: font-url("https://fonts.googleapis.com/css2?family=Inter");
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Inter !important;
  background-color: $white;
}

.mat-expansion-panel-header,
.mat-expansion-panel-content {
  font-family: Inter !important;
}

.mat-expansion-panel-header-title {
  margin-right: 0px !important;
}

p {
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 22px;
}

label {
  font-size: 13px !important;
}

label,
span {
  letter-spacing: -0.1px;
}

.error {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: $red;
}

.cursor {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

// .heading {
//     border-bottom: 3px solid #0d543d;
// }

.mat-toolbar {
  background-color: transparent !important;
}

//********************custom scroll bar Starts*************************//
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 1px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f3f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 10px;
}

//********************custom scroll bar END*************************//

//********************Input type number arrows remove Start*************************//
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

//********************Input type number arrows remove END*************************//

//************Custom material css Starts********************//
.mat-form-field-invalid .mat-input-element,
.mat-warn .mat-input-element {
  caret-color: $danger !important;
}

.mdc-text-field--focused .mdc-notched-outline__leading {
  border-color: $primary-400 !important;
}

.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: $primary-400 !important;
}

.mat-mdc-form-field-flex {
  height: 46px;
}


.mat-mdc-form-field-infix {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.searching-box {
  .mat-mdc-form-field-infix {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .mdc-notched-outline {
    height: 90% !important;
  }
}

// .mat-mdc-form-field-input-control {
//   height: 31px !important;
// }

.mat-mdc-form-field-error-wrapper {
  padding-left: 0px !important;
}

///Purpose is while using mat-form-feild we are having subscript which is used for error & hint purpose to hide that script use this class
.mat-hint-none {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mdc-tab--active .mdc-tab__text-label {
  color: $primary !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.mdc-tab-indicator__content--underline {
  border-color: $primary !important;
  border-radius: 6.17964px !important;
  border-top-width: 3px !important;
}

//************ mat accordian ****************//

.mat-expansion-panel {
  box-shadow: none !important;
  background: white !important;
  border: 1px solid #ebedf3 !important;
  border-radius: 12px !important;

  .mat-expansion-panel-header {
    height: 92px !important;
    background: none !important;
    border-bottom: 1px solid #ebedf3 !important;
    border-radius: 0 !important;
    padding: 0 14px !important;
  }

  .mat-expansion-panel-body {
    // max-height: 10rem !important;
    // overflow: auto !important;
    padding: 0 14px 16px !important; // this needs to change into 0 16px
  }
}

//*******************mat-check-box************************//
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #22332f;
  --mdc-checkbox-selected-hover-icon-color: #22332f;
  --mdc-checkbox-selected-icon-color: #22332f;
  --mdc-checkbox-selected-pressed-icon-color: #22332f;
  --mdc-checkbox-unselected-focus-icon-color: none !important;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
  background: none !important;
}

//************Custom material css Ends********************//

input:focus {
  box-shadow: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $primary !important;
}

.dropdown-menu {
  padding: 0 !important;
}

//***************************Ngx Toaster Custom css Start**********************************//
.toast-success {
  background-color: #51a351;
  opacity: 0.2;
}

.toast-error {
  background-color: $danger;
}

//***************************Ngx Toaster Custom css End**********************************//

.table-sm th,
td {
  font-size: 13px;
}

.table-responsive {
  white-space: nowrap;
}

.table-responsive {

  th,
  td {
    border: 1px solid var(--Colors-black-alpha-05, #1212120D);
    word-wrap: break-word;
  }
  td:first-child {
    border-left: 0 !important;
  }
  td {
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
  }

  th {
    border-radius: 2px 0px;
  }

  .table-title {
    width: 250px !important;
    max-width: 300px;
    min-width: fit-content;
    word-break: break-word;
  }

  .home-text-color {
    color: #0052CC;
  }

  thead th {
    border-top: 0px !important;
    // border-bottom: 0px !important;
  }

  thead tr {
    border: 0px !important;
    outline: 1px solid rgba(211, 211, 211, 1) !important;
    // top: 1px !important;
    z-index: 2;
  }

  table,
  td {
    // padding: 0.3rem 0.5rem !important;
  }

  // .border-top-table {
  //     border-top: 1px solid #d3d3d3ad;
  // }
  .table-hover>tbody>tr:hover>* {
    // background-color: transparent !important;
    background-color: none !important;
    // background-color: #DFE8E9 !important;
  }

  .table-hover tbody tr td:hover {
    // background-color: #DFE8E9 !important;
  }
}

//********************status colors *****************//

@mixin status-color($theme: #27ae60) {
  color: $theme;
}

.dot-approved {
  @include status-color;
}

.dot-rejected {
  @include status-color($theme: #f74545);
}

.dot-pending {
  @include status-color($theme: #fc9106);
}

.dot-refunded {
  @include status-color($theme : rgb(102, 102, 244));
}

.cdk-overlay-container {
  z-index: 1100 !important;
}

.cdk-drag-placeholder {
  opacity: 0 !important;
}

.text-ellipsis {
  display: block;
  // -webkit-line-clamp: 3; // choose this accordingly inside ur component css
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal.fade {
  transition: opacity 0.7s ease !important;
}

// .form-control {
//   // border: 1px solid rgb(55, 54, 54, 0.6) !important;

//   &:hover {
//     border: 1px solid black !important;
//   }
// }

select,
input:focus {
  outline: none !important;
}

.action-btn {
  .dropdown-toggle::after {
    content: none !important;
  }
}

ng-select {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 1px 16px 2px rgba(18, 18, 18, 0.06), 0px 0px 0px 1px rgba(18, 18, 18, 0.1);
  border: none !important;
  padding: 0px !important;
  min-height: 36px !important;

  .ng-select-container {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.form-check-input:checked {
  background-color: #22332f !important;
  border-color: #22332f !important;
}

//****************************Google Calender css START**************************************//

.fc {
  .fc-col-header-cell-cushion {
    text-decoration: none;
    color: black !important;
  }

  .fc-daygrid-day-number {
    text-decoration: none;
    color: black !important;
  }

  .fc-button {
    gap: 20px !important;
    margin: 0 3px !important;
    border-radius: 5px !important;
  }
}

.fc-button-group {
  .fc-timeGridWeek-button {
    margin: 0 10px;
  }

  button {
    background-color: #22332f !important;
    border-color: #22332f !important;
  }

  button:active,
  button:focus,
  .fc-button-active {
    background-color: #000 !important;
    border-color: #000 !important;
  }
}

.fc-h-event {
  padding: 5px;
  background-color: #8fdf82;
  border: 1px solid #8fdf82;
}

.fc-daygrid-dot-event {
  padding: 5px;
  color: white;
  background-color: #8fdf82;
  border: 1px solid #8fdf82;
}

.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  right: 45%;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}

.fc .fc-day-today .fc-daygrid-day-number {
  padding: 4px;
  position: relative;
  z-index: 4;
  background: var(--fc-button-active-bg-color);
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  color: white !important;
}

.bg-teal {
  border-color: #007d88 !important;
  background: #007d88 !important;
}

.demo-app-sidebar-section .bg-teal:hover {
  background: #007d88 !important;
}

//****************************Google Calender css END**************************************//

.custom-pipeline-modal .modal-content {
  overflow: auto !important;
}

ckeditor {
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    min-height: 180px;
    box-shadow: none !important;
    border: 1px solid rgb(209, 205, 205);
  }
}

//********************************Mat-check Box Size change*******************************//
.mat_checkbox {
  .mdc-checkbox__background {
    width: 12px !important;
    height: 12px !important;
    border-width: 1px !important;
    border-radius: 4px;
  }
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #22332f;
  --mdc-radio-selected-hover-icon-color: #22332f;
  --mdc-radio-selected-icon-color: #22332f;
  --mdc-radio-selected-pressed-icon-color: #22332f;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #22332f;
}

//********************************Mat-check Box Size change*******************************//

.main-filter {
  .mat-mdc-form-field-flex {
    height: 40px;
  }

  .mat-mdc-form-field-infix {
    padding-top: 4px;
  }
}

.table-sticky-header {
  position: sticky;
  top: 0;
}

.fixed-table {
  min-height: 26rem !important;
  max-height: 32rem !important;
  overflow: auto !important;
}

//****************** crm-info/client-info ******************//

.info-screen-overflow {
  min-height: 656px !important;
  max-height: 786px !important;
  overflow: auto;
}

.z-index-max {
  z-index: 999;
}

.checkbox-design {
  width: 16px;
  height: 16px;
}

.search-ng-select {
  border: 1px rgb(12, 12, 12, 0.4) solid !important;
  border-radius: 4px !important;
  min-height: 40px !important;
  width: 100% !important;
  margin-left: 6px;
}

.mat-page-custom {
  .mat-mdc-form-field-flex {
    height: 30px !important;
  }

  .mat-mdc-text-field-wrapper {
    width: 90% !important;
  }

  .mdc-text-field--outlined {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .mat-mdc-form-field-infix {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.mat-mdc-paginator-outer-container {
  height: 38px !important;
}

.inactiveClass {
  background-color: #00000020 !important;

  td {
    background-color: transparent !important;
  }
}

//NEW UI DESIGN

.mat-mdc-form-field-input-control {
  height: 100% !important;
}

.mat-mdc-form-field-infix {
  height: 36px !important;
  min-height: 36px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-mdc-form-field-flex {
  height: 36px !important;
}

.mat-mdc-select,
.mat-mdc-select-trigger {
  height: 100% !important;
}

.mat-mdc-text-field-wrapper {
  border-radius: 8px !important;
  background: #FFF !important;
  height: 36px !important;
  box-shadow: 0px 1px 16px 2px rgba(18, 18, 18, 0.06), 0px 0px 0px 1px rgba(18, 18, 18, 0.10) !important;
}

.mat-mdc-input-element ::placeholder {
  color: #A1A1A9 !important;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
  border: none !important;
}

.home_new_ui {
  .mat-mdc-text-field-wrapper {
    border-radius: 8px;
    height: 36px !important;
    padding: 0px 10px;
  }

}

.table-responsive {
  border-radius: 0rem 0rem 0rem 0rem;
  border: 0px solid rgb(172 168 168 / 54%);
  border-bottom: 0px;

  th,
  td {
    padding: 10px !important;
    // border: none !important;
    // border-bottom: 1px solid #F4F4F4 !important;
    word-wrap: normal !important;
  }

  table {
    thead {
      position: sticky;
      top: 0px;
      z-index: 2 !important;
      tr {
        background-color: #F8F8F8;
        outline: none !important;
        // border: 1px solid rgb(172 168 168 / 54%) !important;

        th {
          line-height: 1.5;
          font-size: 13px;
          font-weight: 600;
          background: none !important;
        }
      }
    }

    tbody {
      tr td {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

mat-paginator {
  border-radius: 0rem 0rem 0rem 0rem;
  border: 1px solid #E9E9E9;
  border-top: 0px;
}

.mat-mdc-paginator-outer-container {
  // height: 100% !important;
  // border-top: 1px solid #EAEAEA;
  .mat-mdc-paginator-container {
    justify-content: flex-start !important;
  }
}

// }

.settings-dialog {
  // Form builder settings CSS
  height: 100vh !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  width: 470px !important;
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;

  .modal-content {
    height: 100% !important;
    border-radius: 0 !important;
    position: relative;
    display: block !important;
  }
}

.billing_modal{
  .modal-content {
    padding: 30px;
    overflow: auto !important;
  }
}