.inner-screen-primary-clr {
  color: #141414;
}

.text-primary {
  color: #121212 !important;
}

.text-primary-400 {
  color: #07271e;
}

.text-secondary {
  color: rgba(0, 0, 0, 0.6980392157) !important;
}

.text-success {
  color: rgba(0, 125, 136, 0.6980392157) !important;
}

.text-info {
  color: rgba(255, 255, 255, 0.6980392157) !important;
}

.text-warning {
  color: #f1b44c !important;
}

.text-danger {
  color: #FF0000 !important;
}

.text-light {
  color: #6F6F6F !important;
}

.text-dark {
  color: #000000 !important;
}

.text-green {
  color: #27AE60 !important;
}

.text-inner-info {
  color: #11543e;
  font-weight: 600;
}

.bg-primary {
  background-color: #121212 !important;
}

.bg-secondary {
  background-color: rgba(0, 0, 0, 0.6980392157) !important;
}

.bg-success {
  background-color: rgba(0, 125, 136, 0.6980392157) !important;
}

.bg-info {
  background-color: rgba(255, 255, 255, 0.6980392157) !important;
}

.bg-warning {
  background-color: #f1b44c !important;
}

.bg-danger {
  background-color: #FF0000 !important;
}

.bg-light {
  background-color: #6F6F6F !important;
}

.bg-select {
  background-color: rgba(18, 18, 18, 0.0509803922) !important;
}

.bg-dark {
  background-color: #000000 !important;
}

.bg-gray-light {
  background-color: #EFF2F5 !important;
}

.bg-tag {
  background: rgba(14, 55, 75, 0.1) !important;
}

.bg-gray-dark {
  background-color: #D9D9D9;
}

.bg-high {
  background-color: #F9E3E2 !important;
  color: #752522 !important;
}

.bg-low {
  background-color: #E1E7FD !important;
  color: #302E7C !important;
}

.bg-medium {
  background-color: #FCEED8 !important;
  color: #73321B !important;
}

.bg-green {
  background: #D5FAF1 !important;
  color: #254D4A !important;
}

.bg-dot-medium {
  background-color: #73321B;
}

.bg-dot-low {
  background-color: #302E7C;
}

.bg-dot-high {
  background-color: #752522;
}

.bg-refunded {
  background-color: #EFF2F5;
  color: rgba(87, 87, 85, 0.7019607843);
}

.bg-white {
  background-color: var(#fff);
}

.avatar-xss {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.side-noti-sec .avatar-xss {
  height: 36px;
  width: 36px;
}

.avatar-title {
  align-items: center;
  background-color: #121212;
  color: #fff;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.avatar-group {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
}
.avatar-group .avatar-group-item {
  margin-left: -12px;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 0.2s;
}
.avatar-group .avatar-group-item:hover {
  position: relative;
  transform: translateY(-2px);
}
.avatar-group .avatar-group-item-selected {
  margin-left: -12px;
  border: 3px solid blue;
  border-radius: 50%;
  transition: all 0.2s;
}
.avatar-group .avatar-group-item-selected:hover {
  position: relative;
  transform: translateY(-2px);
}

.btn-group .btn-default {
  background-color: rgba(0, 0, 0, 0.6980392157);
  color: #6F6F6F;
  border: 1px solid rgba(0, 0, 0, 0.6980392157);
}
.btn-group .btn-default.btn-primary {
  background-color: #121212;
  border-color: #121212;
  color: #fff;
}

.btn-primary {
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  background: #121212 !important;
  box-shadow: 0px 0px 0px 1px #121212;
}

.btn-light {
  padding: 6px 12px !important;
  background: #FAFAFA !important;
  box-shadow: 0px 1px 1px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 1px rgba(18, 18, 18, 0.1), 0px 1px 3px 0px rgba(18, 18, 18, 0.1) !important;
}

.btn button,
a {
  outline: none !important;
}

.btn-rounded {
  border-radius: 30px;
}

.btn-dark,
.btn-secondary {
  color: rgba(0, 0, 0, 0.6980392157) !important;
}

.btn-outline-light {
  color: #6F6F6F;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle, .btn-outline-dark:hover,
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle,
.btn-dark:hover {
  color: #6F6F6F;
}

.custom-filter-btn {
  border-radius: 8px;
  border: 1px solid #DEDEDE !important;
  background: #ffffff !important;
  height: 36px !important;
  box-shadow: none !important;
  color: #596066;
  padding: 0px 10px;
}

.btn-group-example .btn {
  position: relative;
}
.btn-group-example .btn:first-child::before {
  display: none;
}
.btn-group-example .btn:before {
  content: "OR";
  position: absolute;
  font-size: 10px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6980392157);
  color: #6F6F6F;
  border: 1px solid #fff;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.btn-label {
  position: relative;
  padding-left: 44px;
}
.btn-label .label-icon {
  position: absolute;
  width: 32px;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-label.btn-light {
  padding: 6px 12px !important;
  background: #f10202 !important;
  box-shadow: 0px 1px 1px 0px rgba(18, 18, 18, 0.2), 0px 0px 0px 1px rgba(18, 18, 18, 0.1), 0px 1px 3px 0px rgba(18, 18, 18, 0.1) !important;
}
.btn-label.btn-light .label-icon {
  background-color: rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.btn-outline-primary {
  border-color: #121212 !important;
  color: #121212;
}
.btn-outline-primary:hover {
  background-color: #121212;
}
.btn-outline-primary:active {
  background-color: #121212 !important;
}

.dropDownfilter .mat-mdc-text-field-wrapper {
  border: 1px solid #DEDEDE !important;
  background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0) 100%), #fff !important;
  box-shadow: 0px 0px 0px 1px rgba(18, 18, 18, 0.0705882353) !important;
}
.dropDownfilter .mat-mdc-select-arrow,
.dropDownfilter .mat-mdc-select-value {
  color: black !important;
}

.card {
  margin-bottom: 24px;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: 600;
}

.card-title-desc {
  color: rgba(0, 0, 0, 0.6980392157);
  margin-bottom: 24px;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  color: rgba(0, 0, 0, 0.6980392157);
  font-weight: 500;
}

.nav-pills > a {
  color: rgba(0, 0, 0, 0.6980392157);
  font-weight: 500;
}

.nav-pills .nav-link.active {
  background-color: #121212 !important;
  color: #fff !important;
  font-weight: 400 !important;
}
.nav-pills .nav-link:hover {
  color: #121212;
  font-weight: 600;
}

.nav-tabs-custom {
  border-bottom: 1px solid rgba(195, 195, 195, 0.5019607843);
}
.nav-tabs-custom .nav-item {
  position: relative;
  color: #000000;
}
.nav-tabs-custom .nav-item .nav-link {
  border: none;
  padding: 10px 16px;
}
.nav-tabs-custom .nav-item .nav-link::after {
  content: "";
  background: #121212;
  height: 3px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  transition: all 250ms ease 0s;
  transform: scale(0);
}
.nav-tabs-custom .nav-item .nav-link.active {
  background-color: transparent;
  color: black;
  font-weight: 600;
}
.nav-tabs-custom .nav-item .nav-link.active:after {
  transform: scale(1);
}
.nav-tabs-custom .nav-item .nav-link:hover, .nav-tabs-custom .nav-item .nav-link:focus {
  color: black;
}

.vertical-nav .nav .nav-link {
  padding: 24px 16px;
  text-align: center;
  margin-bottom: 8px;
}
.vertical-nav .nav .nav-link .nav-icon {
  font-size: 24px;
}

body {
  overflow-x: hidden !important;
}

.pointer {
  cursor: pointer;
}

.limit-line-length-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.limit-line-height-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.small-margin {
  margin-top: 2px;
}

.from-bottom-right .modal-dialog {
  position: absolute !important;
  right: 10px !important;
  width: 340px !important;
  bottom: -10px !important;
  max-height: calc(100vh - 78px) !important;
  border-radius: 16px;
}
.from-bottom-right .modal-dialog .modal-content {
  border: none;
  height: 100%;
  padding-bottom: 8px;
  background: rgba(203, 247, 252, 0.94);
}

.from-bottom-right.modal.fade .modal-dialog {
  transition: transform 0.5s ease-out;
  transform: translateX(100%);
}

.from-bottom-right.modal.show .modal-dialog {
  transform: none;
  animation: rightAnimation 0.5s ease !important;
}

@keyframes rightAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-3px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(3px);
  }
}
.table-responsive::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.table-responsive:hover {
  overflow: auto !important;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: white;
}

.table-responsive:hover::-webkit-scrollbar-track {
  background-color: #f3f1f1;
}

.table-responsive:hover::-webkit-scrollbar-thumb {
  visibility: visible !important;
}

.auto-hide-scroll-bar::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.auto-hide-scroll-bar:hover {
  overflow: auto !important;
}

.auto-hide-scroll-bar::-webkit-scrollbar-track {
  background-color: white;
}

.auto-hide-scroll-bar:hover::-webkit-scrollbar-track {
  background-color: #f3f1f1;
}

.auto-hide-scroll-bar:hover::-webkit-scrollbar-thumb {
  visibility: visible !important;
}

.custom-table-filter-div-height {
  height: calc(100vh - 200px);
  transition: all 0.3s;
}
.custom-table-filter-div-height tr {
  position: relative;
}

.custom-height {
  height: calc(100vh - 101px);
  background: white;
}
.custom-height .tab-pane {
  height: 100%;
}

.custom-table-div-more-height, .custom-table-div-crm-height, .custom-table-div-less-height, .custom-table-div-height-home {
  height: calc(100vh - 148px);
}
.custom-table-div-more-height .border-top-table, .custom-table-div-crm-height .border-top-table, .custom-table-div-less-height .border-top-table, .custom-table-div-height-home .border-top-table {
  position: sticky;
  top: 0px;
}
.custom-table-div-more-height .table-responsive, .custom-table-div-crm-height .table-responsive, .custom-table-div-less-height .table-responsive, .custom-table-div-height-home .table-responsive {
  flex-grow: 1;
  height: 100%;
}
.custom-table-div-more-height .table-responsive table, .custom-table-div-crm-height .table-responsive table, .custom-table-div-less-height .table-responsive table, .custom-table-div-height-home .table-responsive table {
  max-height: 100%;
}

.custom-table-div-height-home {
  height: calc(100vh - 127px);
  transition: all 0.3s;
}

.custom-details-div-height {
  height: calc(100vh - 172px);
}

.custom-table-div-less-height {
  height: calc(100vh - 168px);
}

.custom-table-div-crm-height {
  height: calc(100vh - 152px);
}
.custom-table-div-crm-height .fixed-table {
  max-height: 100% !important;
}

.table-head-sticky, .user-settings-div-height {
  position: sticky;
  top: 0px;
  z-index: 2;
}

.user-settings-div-height {
  height: calc(100vh - 258px);
  overflow-y: auto !important;
}

.height-max-content {
  height: max-content;
}

.mat-mdc-option {
  background: white !important;
}

.mat-mdc-paginator-container {
  min-height: 42px;
}

.ck-error .ck.ck-editor {
  border: 1px solid red;
}

.tableColumnLists mat-form-field {
  box-shadow: 0px 16px 20px -24px;
}

.cdk-overlay-pane {
  min-width: fit-content !important;
  margin-top: 2px;
}

.page_customised-sec .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 4px !important;
  padding-bottom: 0px !important;
}

.customised-sec span, .search-customised-sec span {
  text-align: left;
}
.customised-sec button, .search-customised-sec button {
  height: 32px;
}
.customised-sec .mat-mdc-form-field-infix, .search-customised-sec .mat-mdc-form-field-infix {
  width: fit-content !important;
  min-width: 60px !important;
  min-height: auto !important;
  padding-top: 0px !important;
}
.customised-sec .cust-date-picker .mat-mdc-form-field-infix, .search-customised-sec .cust-date-picker .mat-mdc-form-field-infix {
  width: 180px !important;
  min-width: 60px !important;
  min-height: auto !important;
  height: 34px !important;
  padding-top: 4px !important;
}
.customised-sec .mat-mdc-form-field-flex, .search-customised-sec .mat-mdc-form-field-flex {
  height: auto !important;
}
.customised-sec .searching-box .mat-mdc-form-field-infix, .search-customised-sec .searching-box .mat-mdc-form-field-infix {
  padding: 1px !important;
}
.customised-sec .mat-mdc-select-value, .search-customised-sec .mat-mdc-select-value {
  padding-right: 8px !important;
}
.customised-sec .mat-mdc-form-field-icon-prefix > .mat-icon, .search-customised-sec .mat-mdc-form-field-icon-prefix > .mat-icon {
  padding: 1px 0px 0px 8px !important;
  scale: 0.8;
}
.customised-sec .searching-box .mdc-notched-outline, .search-customised-sec .searching-box .mdc-notched-outline {
  height: 100% !important;
}

.search-customised-sec .mat-mdc-form-field-infix {
  padding-top: 0px !important;
}

.mat-customised-sec .mat-mdc-form-field-flex {
  height: 34px !important;
  min-height: auto !important;
}

.mat-date-range-input-container {
  height: 100%;
}

.container-width-height {
  max-width: 100%;
  height: calc(100vh - 130px);
}

::-webkit-scrollbar {
  height: 2px !important;
}

.ai-modal .modal-content {
  border-radius: 10px;
}

.ck.ck-balloon-panel {
  z-index: 100000 !important;
}

.ai-modal .modal-content {
  border-radius: 24px;
}

.line-height-1 {
  line-height: 1;
}

.margin-right-14 {
  margin-right: 14px;
}

tr:hover {
  background: #F8F8F8 !important;
}

.newUI .bottom-border {
  border-bottom: 1px solid #F4F4F4 !important;
}
.newUI .no-gutters {
  align-items: center;
}
.newUI .info-accord .text-inner-info {
  color: #09090B;
  font-weight: 400;
  font-size: 14px;
}
.newUI .info-accord .text-name {
  color: #09090B;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
}

.word-break {
  word-break: break-all;
}

.action-btn {
  box-shadow: 0px 1px 1px 0px rgba(18, 18, 18, 0.1), 0px 0px 0px 1px rgba(18, 18, 18, 0.07), 0px 1px 3px 0px rgba(18, 18, 18, 0.1);
}

.table-header-color {
  color: #09090B !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}

.title-text-clr {
  color: #151515;
}

.label-text-clr {
  color: #09090B;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-shrink {
  flex: 1 1 auto;
}

.switch {
  width: 40px;
  height: 24px;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.slider:before {
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 1px;
  top: 2px;
}

.ck-toolbar.ck-toolbar_grouping {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 8px 8px 0px 0px !important;
}

.ck.ck-content.ck-editor__editable {
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px 0px 8px 8px !important;
  border-top: 1px solid rgb(209, 205, 205);
}

.ck-editor {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 1px 16px 2px rgba(18, 18, 18, 0.06), 0px 0px 0px 1px rgba(18, 18, 18, 0.1);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-style: solid !important;
  border-color: #f44336 !important;
  border-width: 1px 1px 1px 0px !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading {
  border-style: solid !important;
  border-color: #f44336 !important;
  border-width: 1px 0px 1px 1px !important;
}

.border-none {
  border: none !important;
}

.new-design-padding {
  padding: 0px 11px 0px 11px;
}

.home-page-table-view .table-responsive table thead {
  z-index: 2;
}
.home-page-table-view .table-responsive table thead th {
  font-size: 14px;
}
.home-page-table-view .table-responsive table tbody tr td {
  font-weight: 500;
  font-size: 12px;
  min-width: 100px;
  max-width: 150px;
  word-break: break-all;
}